import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';

const PrivacyPolicyPage = () => {
   // Querying and storing Data Stored in Gatsby GraphQL query.
    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: {title: "Privacy Policy"}) {
                nodes {
                    content
                }
            }
        }
    }
   `)
    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);
    const [loader, setLoader] = useState(true);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: `
                {   
                    pages(where: {title: "Privacy Policy"}) {
                        nodes {
                          content
                        }
                      }
                }
                `,
            }),
       })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .then(setLoader(false))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])

    // Storing All Live Data in Variables to display it
    let privacy_policy_detail = existingData.pages.nodes[0].content;
    console.log(privacy_policy_detail);
   return (
    <>
        <div className="page-template-default">
        { 
            parse(`${privacy_policy_detail}`)
        }
        </div>
    </>
        
   )
}

export default PrivacyPolicyPage;