import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicyPage from "../components/Privacy Policy/privacy-policy"

const PrivacyPolicy = () => (
  
  <div className="privacy-policy-main-wrapper page-template-default">
    <Layout>
        <SEO title="Privacy Policy" />
        <div id="privacy-policy-page" className="main-content">
            <div className="container">
                <PrivacyPolicyPage/>     
            </div>       
        </div>
    </Layout>
  </div>
)
export default PrivacyPolicy